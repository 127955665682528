<script setup>
import { ref, computed, onMounted, onBeforeMount, watch } from 'vue'
import { Tooltip } from 'flowbite-vue'
import { useGlobalStore } from '@/stores/GlobalStore';
import { useStrapiStore } from '@/stores/StrapiStore'
import { useUserStore } from '@/stores/UserStore'
import { useRoute } from 'vue-router';
import { formatYoutubeURL } from '@/helpers/text'

const globalStore = useGlobalStore()
const strapiStore = useStrapiStore()
const userStore = useUserStore()
const route = useRoute()

const windowWidth = computed(() => globalStore.windowWidth)
const windowHeight = computed(() => globalStore.windowHeight)

const user = computed(() => userStore.user)

const formatIfDynamicRoutePath = (routePath) => {
    if(routePath.includes('/batches?sortBy=')){
    return '/batches'
  }
  const pattern = /^\/batches\/(\d+)$/
  return pattern.test(routePath) ? routePath.replace(pattern, '/batches/selectedBatch') : routePath
}

const coachingCard = computed(() => {
  const routePath = formatIfDynamicRoutePath(route.fullPath)

  const matchingCards = strapiStore.coachingCards.filter(
    card => card.path === routePath
  )

  if (matchingCards.length === 0) {
    return {}
  }
  matchingCards.sort((a, b) => a.priority - b.priority)
  const localStorageCards = retrieveLocalStorageCards()
  if (!localStorageCards){
    return matchingCards[0]
  }

  const notDismissedCard = matchingCards.find(card => !localStorageCards.dismissedCoachingIds.includes(card.id));

  return notDismissedCard ? notDismissedCard : {}

})

const expandedView = ref(false)
const pinnedGlobalVideoEl = ref()
const pinnedCardRect = ref({})

const retrieveLocalStorageCards = () => {
  if (user.value) {
    const dismissedCardDataJSON = localStorage.getItem(`dismissedCoaching${user.value.id}`);
    return dismissedCardDataJSON ? JSON.parse(dismissedCardDataJSON) : null;
  }
  return null
}

const localStorageCoachingCards = ref(retrieveLocalStorageCards())

const storeDismissedCoachingUser = () => {
  let dismissedCoachingDataToStore = null
  if(!localStorageCoachingCards.value){
    dismissedCoachingDataToStore = { dismissedCoachingIds: [coachingCard.value.id]}
  }
  else{
    dismissedCoachingDataToStore = localStorageCoachingCards.value
    if(!localStorageCoachingCards.value.dismissedCoachingIds.includes(coachingCard.value.id)){
      dismissedCoachingDataToStore.dismissedCoachingIds.push(coachingCard.value.id)
    }
  }
  localStorage.setItem(`dismissedCoaching${user.value?.id}`, JSON.stringify(dismissedCoachingDataToStore))
  localStorageCoachingCards.value = dismissedCoachingDataToStore
}

const handleDismiss = () => {
  if(expandedView.value){
    toggleExpandView()
  }
  else{
    storeDismissedCoachingUser()
    tipOpen.value = false
  }
}

watch([route], () => {
  isCardMoving.value = false
})

watch([coachingCard], () => {
  localStorageCoachingCards.value = retrieveLocalStorageCards()
  if(localStorageCoachingCards.value){
    if(localStorageCoachingCards.value.dismissedCoachingIds.includes(coachingCard.value.id)){
      tipOpen.value = false
    }
    else {
      tipOpen.value = Object.keys(coachingCard.value).length ? true : false
    }
  }
  else{
    tipOpen.value = Object.keys(coachingCard.value).length ? true : false
  }
})

onBeforeMount(() => {
  strapiStore.fetchCoachingCards(route.fullPath)
})

const isCardMoving = ref(false)

const widgetClasses = computed(() => {
  if (expandedView.value) {
    return '!top-0 !left-0 w-full h-full'
  } else {
    return `left-[calc(100%-320px-24px)] w-80 border border-gray-300 rounded-lg shadow-[0_2px_22px_0_rgba(9,38,67,.29)]`
  }
})

const widgetStyles = computed(() => {
  let topPart = 0

  if (pinnedCardRect.value?.top && expandedView.value) {
    topPart = pinnedCardRect.value.top
  } else {
    if (!isCardMoving.value) {
      return {
        bottom: '10px'
      }
    }
    // topPart = windowHeight.value - pinnedGlobalVideoEl.value?.offsetHeight - 24
    topPart = windowHeight.value - 295 - 24
  }
  return {
    top: topPart + 'px'
  }
})

const youtubeDimensions = computed(() => expandedView.value? {width:900, height:450} : {width:285, height:155})

const toggleExpandView = () => {
  if (!expandedView.value) {
    pinnedCardRect.value = pinnedGlobalVideoEl.value?.getBoundingClientRect()
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'visible'
  }
  expandedView.value = !expandedView.value
  isCardMoving.value = false
}

const tipOpen = ref(false)
const showVideoControls = ref(false)
const pinnedVideo = ref()
const playVideo = _ => {
  pinnedVideo.value.play()
  showVideoControls.value = true
}

const getTargetForUrl = (url) => {
  return url.includes('http') ? '_target' : '_self'
}

onMounted(() => {
  /* FRONTEND TASKS: Globalize Grip Function */
  let gMouseDownX = 0;
  let gMouseDownY = 0;
  let gMouseDownOffsetX = 0;
  let gMouseDownOffsetY = 0;
  let gripEl = document.getElementById('pinnedGlobalVideoGrip')
  let cardEl = document.getElementById('pinnedGlobalVideoEl')

  function addListeners() {
    gripEl.addEventListener('mousedown', mouseDown, false);
    window.addEventListener('mouseup', mouseUp, false);
  }

  function mouseUp() {
    window.removeEventListener('mousemove', cardMove, true);
  }

  function mouseDown(e) {
    gMouseDownX = e.clientX;
    gMouseDownY = e.clientY;

    //The following block gets the X offset (the difference between where it starts and where it was clicked)
    let leftPart = (windowWidth.value - cardEl.offsetWidth - 24) + "px";
    if(!cardEl.style.left)
      leftPart+="0px"; //In case this was not defined as 0px explicitly.
    else
      leftPart = cardEl.style.left;
    let leftPos = leftPart.indexOf("px");
    let leftNumString = leftPart.slice(0, leftPos); // Get the X value of the object.
    gMouseDownOffsetX = gMouseDownX - parseInt(leftNumString,10);

    //The following block gets the Y offset (the difference between where it starts and where it was clicked)
    let topPart = (windowHeight.value - cardEl.offsetHeight - 24) + "px";
    if(!cardEl.style.top)
      topPart+="0px";     //In case this was not defined as 0px explicitly.
    else
      topPart = cardEl.style.top;
    let topPos = topPart.indexOf("px");
    let topNumString = topPart.slice(0, topPos);    // Get the Y value of the object.
    gMouseDownOffsetY = gMouseDownY - parseInt(topNumString,10);

    window.addEventListener('mousemove', cardMove, true);
  }

  function cardMove(e){
    isCardMoving.value = true
    var cardEl = document.getElementById('pinnedGlobalVideoEl');
    let topAmount = e.clientY - gMouseDownOffsetY;
    if (
      topAmount < (windowHeight.value - cardEl.offsetHeight - 20)
      && topAmount > 60
    ) {
      cardEl.style.top = topAmount + 'px';
    }

    let leftAmount = e.clientX - gMouseDownOffsetX;
    if (
      leftAmount < (windowWidth.value - cardEl.offsetWidth - 20)
      && leftAmount > 74
    ) {
      cardEl.style.left = leftAmount + 'px';
    }
  }

  addListeners();
})

</script>

<template>
  <Transition name="fade">
    <section
      class="fixed z-[1099] bg-white flex flex-col"
      :class="widgetClasses"
      :style="widgetStyles"
      v-show="tipOpen"
      id="pinnedGlobalVideoEl"
      ref="pinnedGlobalVideoEl"
      ondragstart="return false;"
    >
      <header class="flex items-center gap-x-3 px-3 py-3 border-b">
        <button @click="handleDismiss">
          <Icon icon="heroicons:x-mark" class="text-base" />
        </button>
        <Tooltip
          placement="top-start" theme="dark" class="flex-1 -ml-2"
          :class="{ 'pointer-events-none': ((coachingCard.title?.length || 0) + (coachingCard.headline?.length || 0) < 35) || expandedView }"
        >
          <template #trigger>
            <p class="font-semibold flex-1" :class="expandedView ? 'text-sm ml-3' : 'text-xs truncate w-[204px] ml-2'">
              {{ coachingCard.title }}: {{ coachingCard.headline }}
            </p>
          </template>
          <template #content>
            <div class="base-tooltip-2 max-w-[280px]">
              {{ coachingCard.title }}: {{ coachingCard.headline }}
            </div>
          </template>
        </Tooltip>

        <button @click="toggleExpandView">
          <Icon icon="heroicons:arrows-pointing-in" class="text-xl" v-if="expandedView" />
          <Icon icon="gg:arrows-expand-right" class="text-xl p-px" v-else />
        </button>
        <button id="pinnedGlobalVideoGrip" v-show="!expandedView">
          <Icon icon="tabler:grip-vertical" class="text-xl text-gray-500" />
        </button>
      </header>
      <section class="overflow-y-auto">
        <div class="px-4 py-3 flex flex-col" :class="expandedView ? 'max-w-[940px] mx-auto gap-y-6' : 'gap-y-3'">
          <div class="relative" @click="playVideo">
            <video v-if="coachingCard.video && Object.keys(coachingCard.video).length > 0"
              class="rounded-lg object-contain mx-auto"
              :class="expandedView ? 'w-full h-[528px]' : 'h-40'"
              ref="pinnedVideo"
              :src="coachingCard.video"
              :poster="coachingCard.coverImage"
              :controls="showVideoControls"
            ></video>
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-black/30 rounded-full flex items-center justify-center cursor-pointer"
              :class="expandedView ? 'w-12 h-12 text-3xl' : 'w-10 h-10 text-2xl'"
              v-if="coachingCard.video && Object.keys(coachingCard.video).length > 0 && !showVideoControls"
            >
              <Icon icon="heroicons:play-solid" class="text-white" />
            </div>
          </div>
          <div v-if="coachingCard.youtubeLink" class="relative flex items-center justify-center">
            <iframe
              :height="youtubeDimensions.height" :width="youtubeDimensions.width"
              :src="formatYoutubeURL(coachingCard.youtubeLink)"
              frameborder="0"
              allowfullscreen
              class="rounded-xl"
            ></iframe>
          </div>
          <p class="text-sm">{{ coachingCard.description }}</p>
          <a
            class="inline-flex items-center gap-x-1.5 text-sm font-semibold text-tint-1 transition hover:text-tint-2"
            :href="coachingCard.ctaLink"
            :target="getTargetForUrl(coachingCard.ctaLink)"
            v-if="coachingCard.ctaLabel"
          >
            {{ coachingCard.ctaLabel }} <Icon icon="heroicons:arrow-right-16-solid" class="text-base" />
          </a>
        </div>
      </section>
    </section>
  </Transition>
</template>
