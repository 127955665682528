import { ALL_CONNECTION_CHANNELS } from '@/constants'

export const getDefaultTemplateForStore = (listingTemplates, selectedTemplate) => {
  return selectedTemplate?.id ? selectedTemplate : listingTemplates.find(item => item?.default) || listingTemplates[0]
}

export const getConnections = (user = {}) => {
  return ALL_CONNECTION_CHANNELS.reduce((acc, item) => {
    let connection = { ...item, active: false }

    if (user[item.field] && item.key !== 'collx') {
      connection = { ...connection, active: true }
    }

    if (item.key === 'collx' && user.collx_enabled && user.collx_user_id) {
      connection = { ...connection, active: true }
    }

    acc.push(connection)

    return acc
  }, [])
}