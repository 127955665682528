import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { useBatchStore } from '@/stores/BatchStore'
import { useConnectionStore } from '@/stores/ConnectionStore'

const routes = [
  { 
    path: '/mobile-scan/:batchId', 
    name: 'MobileScan', 
    component: () => import('@/views/MobileScan/index.vue'),
    children: [
      { path: 'sides', name: 'MobileScanSides',  meta: { requireAuth: true }, component: () => import('@/views/MobileScan/SelectSides.vue') },
      { path: 'uploads', name: 'MobileScanUpload', meta: { requireAuth: true }, component: () => import('@/views/MobileScan/UploadPhoto.vue') },
      { path: 'completed', name: 'MobileScanCompleted', meta: { requireAuth: true }, component: () => import('@/views/MobileScan/Completed.vue') }
    ]
  },
  { path: '/mobile-scan/session-ended', name: 'SessionMobileScanEnded', meta: { requireAuth: false }, component: () => import('@/views/MobileScan/SessionEnded.vue') },
  { 
    path: '/otp-links/:password', 
    name: 'OtpLinks', 
    component: () => import('@/views/MobileScan/OtpLinks.vue'),
  },
  //Verification
  { path: '/verify-email/:token', name: 'Verification', component: () => import('@/views/Auth/VerificationEmail.vue') },
  { path: '/reset-password/:token', name: 'ResetVerification', component: () => import('@/views/Auth/VerificationEmail.vue') },
  // Auth
  { path: '/sign-in', name: 'SignIn', component: () => import('@/views/Auth/SignIn.vue')},
  // Sign up
  { 
    path: '/sign-up', 
    name: 'SignUp', 
    redirect: '/sign-up/email',
    component: () => import('@/views/Auth/SignUp/index.vue'),
    children: [
      { path: 'email', name: 'SignUpEmail', component: () => import('@/views/Auth/SignUp/EmailPage.vue') },
    ]
  },
  // Account creation paths
  {
    path: '/account-creation',
    component: () => import('@/views/Auth/AccountCreation/index.vue'),
    redirect: '/account-creation/user',
    name: 'AccountCreation',
    beforeEnter: async (to, from) => {
      const userStore = useUserStore()
      const batchStore = useBatchStore()

      if (userStore.user?.status === 'active') {
        return '/batches'
      }

      return true
    },
    children: [
      { path: 'user', component: () => import('@/views/Auth/AccountCreation/UserPage.vue') },
      { path: 'survey', component: () => import('@/views/Auth/AccountCreation/SurveyPage.vue') },
      { path: 'organization', component: () => import('@/views/Auth/AccountCreation/OrganizationPage.vue') },
      { path: 'plan', component: () => import('@/views/Auth/AccountCreation/PlanPage.vue') },
      { path: 'payment', component: () => import('@/views/Auth/AccountCreation/PaymentPage.vue') },
    ]
  },
  // Reset Flow paths
  { path: '/reset-password', name: 'ResetPassword', component: () => import('@/views/Auth/ResetPassword.vue')},
  { path: '/set-password', name: 'SetPassword', component: () => import('@/views/Auth/SetPassword.vue')},

  { path: '/email-sent', name: 'EmailSent', component: () => import('@/views/Auth/EmailSent.vue')},
  { path: '/link-expired', name: 'LinkExpired', component: () => import('@/views/Auth/LinkExpired.vue')},

  { 
    path: '/', 
    name: 'Homepage',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Home/index.vue')
  },

  { 
    path: '/changelog', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Home/Changelog/index.vue')
  },

  { 
    path: '/batches/:batchId/add', 
    meta: { requireAuth: true },
    component: () => import('@/views/Batches/AddCards/index.vue'),
    redirect: (route) => {
      return `${route.fullPath}/types`
    }, 
    beforeEnter: async (to, from) => {
      const batchStore = useBatchStore()

      if (batchStore.config?.subscription && !batchStore.config?.hasActiveSubscription) {
        return '/batches'
      }

      return true
    },
    children: [
      { 
        path: 'types', 
        name: 'AddTypes',
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/AddCardOptions.vue') 
      },
      { 
        path: 'sides', 
        name: 'AddSides',
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/ChooseSides.vue') 
      },
      {
        path: 'upload', 
        name: 'AddUpload', 
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/UploadImages.vue') 
      },
      {
        path: 'cert', 
        name: 'AddCert', 
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/CertImport.vue') 
      },
      {
        path: 'scan', 
        name: 'AddScan', 
        component: () => import('@/views/Batches/AddCards/MobileScan.vue') 
      },
    ]
  },
  {
    path: '/batches',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue'),
    children: [
      { path: '', name: 'Batches', component: () => import('@/views/Batches/AllBatches/index.vue') },
      { 
        path: ':batchId', 
        component: () => import('@/views/Batches/BatchDetails.vue'),
        name: 'BatchDetailsFlow',
        children: [
          { path: '', name: 'BatchDetail', component: () => import('@/views/Batches/BatchDetails.vue') },
          { 
            path: 'inspector',  
            component: () => import('@/views/Batches/BatchDetails.vue'),
            children: [
              { path: '', name: 'InspectorDetail', component: () => import('@/views/Batches/BatchDetails.vue') },
              { path: ':cardId', name: 'InspectorDetailWithCardId', component: () => import('@/views/Batches/BatchDetails.vue') },
            ]
          },
          { 
            path: ':cardId',
            component: () => import('@/views/Batches/BatchDetails.vue'),
            children: [
              { path: '', name: 'BatchDetailByCard', component: () => import('@/views/Batches/BatchDetails.vue') },
              { path: ':tab', name: 'InspectorDetailByCard', component: () => import('@/views/Batches/BatchDetails.vue') },
            ] 
          },
          
        ]
      },
    ]
  },
  {
    path: '/create-batch',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Batches/CreateBatch/index.vue'),
    redirect: '/create-batch/general-settings',
    beforeEnter: async (to, from) => {
      const batchStore = useBatchStore()

      if (batchStore.config?.subscription && !batchStore.config?.hasActiveSubscription) {
        return '/batches'
      }

      return true
    },
    children: [
      { path: 'general-settings', component: () => import('@/views/Batches/CreateBatch/GeneralSettings.vue') },
      { path: 'optional-details', component: () => import('@/views/Batches/CreateBatch/OptionalDetails.vue') },
      // { path: 'add-card-options', component: () => import('@/views/Batches/CreateBatch/AddCardOptions.vue') },
      // { path: 'choose-sides', component: () => import('@/views/Batches/CreateBatch/ChooseSides.vue') },
      // { path: 'upload-images', component: () => import('@/views/Batches/CreateBatch/UploadImages.vue') },
    ]
  },
  {
    path: '/inventory', 
    name: 'Inventory',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Inventory/index.vue'),
  },
  {
    path: '/settings', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Settings/index.vue'),
    redirect: '/settings/account',
    children: [
      { path: 'account', component: () => import('@/views/Settings/Account.vue') },
      { path: 'plans', component: () => import('@/views/Settings/Plans.vue') },
      { path: 'inventory', component: () => import('@/views/Settings/Inventory.vue') },
      { path: 'notifications', component: () => import('@/views/Settings/Notifications.vue') },
      { path: 'custom-fields', component: () => import('@/views/Settings/CustomFields.vue') },
      { path: 'templates', component: () => import('@/views/Settings/Templates.vue') },
    ]
  },
  {
    path: '/connections', 
    name: 'Connections',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Connections/Channels.vue'),
    beforeEnter: async (to, from) => {
      const connectionStore = useConnectionStore()
      const type = to.fullPath.includes('collx') ? 'collxListingTemplates' : 'listingTemplates'
      const listingType = to.fullPath.includes('collx') ? 'collx' : 'ebay'

      await connectionStore.fetchTemplates(type, listingType)
      connectionStore.templateCreation = true
      return true
    },
    children: [
      { path: 'ebay/shipping-settings', component: () => import('@/views/Connections/EbayDetails/ListingTemplate/ShippingSettings.vue') },
      { path: 'ebay/payment-return-settings', component: () => import('@/views/Connections/EbayDetails/ListingTemplate/PaymentReturnSettings.vue') },
      { path: 'ebay/listing-information', component: () => import('@/views/Connections/EbayDetails/ListingTemplate/ListingInformation.vue') },
      {
        path: 'ebay',
        component: () => import('@/views/Connections/EbayDetails/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/EbayDetails/Overview.vue') },
          { path: 'templates', component: () => import('@/views/Connections/EbayDetails/Templates.vue') },
          {
            path: 'export-history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/EbayDetails/ExportHistory/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/EbayDetails/ExportHistory/ExportInfo.vue') },
            ]
          },
        ]
      },
      {
        path: 'collx',
        component: () => import('@/views/Connections/CollxDetails/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/CollxDetails/Overview.vue') },
          // { path: 'listing-information', component: () => import('@/views/Connections/CollxDetails/ListingInformation.vue') },
          { path: 'connection-settings', component: () => import('@/views/Connections/CollxDetails/ConnectionSettings.vue') },
          {
            path: 'export-history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/CollxDetails/ExportHistory/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/CollxDetails/ExportHistory/ExportInfo.vue') },
            ]
          },
        ]
      },
      {
        path: 'collx/connect',
        component: () => import('@/views/Connections/CollxDetails/Connection/index.vue'),
        children: [
          {
            path: 'account-connection',
            component: () => import('@/views/Connections/CollxDetails/Connection/AccountConnection.vue')
          },
          {
            path: 'listing-information',
            component: () => import('@/views/Connections/CollxDetails/Connection/ListingInformation.vue')
          },
        ]
      },
      {
        path: 'shopify',
        component: () => import('@/views/Connections/ShopifyDetails/index.vue'),
        children: [
          { path: '', component: () => import('@/views/Connections/ShopifyDetails/Overview.vue') },
          {
            path: 'export-history',
            component: () => import('./RouterView.vue'),
            children: [
              { path: '', component: () => import('@/views/Connections/ShopifyDetails/ExportHistory/index.vue') },
              { path: ':id', component: () => import('@/views/Connections/ShopifyDetails/ExportHistory/ExportInfo.vue') },
            ]
          },
          {
            path: 'export-settings',
            component: () => import('@/views/Connections/ShopifyDetails/ExportSettings.vue')
          },
        ]
      },
      // { path: ':channel', component: () => import('@/views/Connections/legacy/ChannelDetails.vue') },
    ]
  },
  { 
    path: '/refer-friend', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  { 
    path: '/feedback', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  { 
    path: '/help', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  { 
    path: '/billing', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  { 
    path: '/desktop-app', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  { 
    path: '/pricing', 
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },

  {
    path: '/exception-detected',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/ExceptionDetectedPage.vue'),
  },

  // 404 not found page
  {
    path: '/:catchAll(.*)',
    name: 'NotFoundPage',
    component: () => import('@/views/NotFoundPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  },
})

router.beforeEach(to => {
  const userStore = useUserStore()
  const batchStore = useBatchStore()
  batchStore.loading = true // XXX FIXME HACK

  if (to.meta.requireAuth) {
    if (!userStore.loggedIn) {
      userStore.redirectTo = to.fullPath
      return '/sign-in?backTo=' + to.fullPath
    }
  }

  if (to.meta.requireFields) {
    if (userStore.user?.status === 'pending') {
      return '/account-creation'
    }
  }
})

export default router