<script setup>
import { computed, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import GlobalSearchComponent from "../GlobalSearch/index.vue";
import SidebarToggleButton from "./SidebarToggleButton.vue";
import AddCardsMenu from "./AddCardsMenu.vue";
import UpgradeButton from "./UpgradeButton.vue";
import UserMenu from "./UserMenu.vue";
import { useGlobalStore } from "@/stores/GlobalStore";
import { useUserStore } from "@/stores/UserStore";
import { useBatchStore } from "@/stores/BatchStore";
import { useSearchStore } from "@/stores/SearchStore";
import { useCreateBatchStore } from "@/stores/CreateBatchStore";

const closeSidebar = inject("closeSidebar");
const showSystemNotificationBar = inject("showSystemNotificationBar");

const router = useRouter();
const route = useRoute();
const globalStore = useGlobalStore();
const userStore = useUserStore();
const batchStore = useBatchStore();
const searchStore = useSearchStore();
const createBatchStore = useCreateBatchStore();

const user = computed(() => userStore.user);
const loggedIn = computed(() => userStore.loggedIn);
const sidebar = computed(() => globalStore.sidebar);

const showAdminBanner = computed(() => globalStore.showAdminBanner);
const toggleSidebar = () => {
  globalStore.sidebar = !globalStore.sidebar;
  localStorage.setItem("sidebar", JSON.stringify(globalStore.sidebar));
};

const handleOpenSearch = () => {
  searchStore.triggerOpenSearchResult = true;
};

const handleUpgrade = () => {
  globalStore.openPlansModal = true;
  router.push("/settings/plans");
};

const signOut = () => {
  userStore.logOut();
  closeSidebar();
};

const cdpBatchesSearch = () => {
  if (batchStore.selectedBatch?.type) {
    batchStore.batchKind = batchStore.selectedBatch.type;
    searchStore.searchUserBatches({ kind: batchStore.selectedBatch.type });
  } else {
    searchStore.searchUserBatches();
  }

  searchStore.updateSearchStateBasedOnDataObject({
    cdp_search: {
      ...searchStore.cdp_search,
      open: true,
      state: "batches",
      locationOfSearchCall: "add-button",
    },
    searchState: "result",
    selectedFilter: "Batches",
  });
};

const handleCreateNewBatch = () => {
  batchStore.selectedBatchId = null;
  createBatchStore.clearCreatingBatchInfo();
  router.push("/create-batch/general-settings");
};

const isBatchDetailsFlow = computed(() => {
  return (
    route.matched &&
    route.matched.length > 1 &&
    route.matched[1].name === "BatchDetailsFlow"
  );
});

const addToCurrentBatch = () => {
  if (isBatchDetailsFlow.value) {
    router.push(`/batches/${route.params.batchId}/add/types`);
  }
};
</script>

<template>
  <header
    class="fixed left-0 w-full z-[1000] h-12 bg-tint-1"
    :class="showAdminBanner || showSystemNotificationBar ? 'top-10' : 'top-0'"
  >
    <div class="flex items-center justify-between h-full py-1.5 px-4 md:px-[18px]">
      <div class="flex items-center gap-x-6">
        <SidebarToggleButton :sidebar="sidebar" @toggleSidebar="toggleSidebar" />

        <AddCardsMenu
          v-if="userStore.plan"
          :isBatchDetailsFlow="isBatchDetailsFlow"
          @addToCurrentBatch="addToCurrentBatch"
          @createNewBatch="handleCreateNewBatch"
          @cdpBatchesSearch="cdpBatchesSearch"
          @closeSidebar="closeSidebar"
        />
      </div>

      <GlobalSearchComponent v-if="userStore.plan" />

      <div class="flex items-center gap-x-4">
        <UpgradeButton
          v-if="
            batchStore.config?.subscription?.plan_id !== batchStore.topPlan?.id ||
            !batchStore.config?.hasActiveSubscription
          "
          :batchStore="batchStore"
          @upgrade="handleUpgrade"
        />

        <button
          class="md:hidden text-white"
          @click="handleOpenSearch"
          aria-label="Handle Open Search"
        >
          <Icon icon="heroicons:magnifying-glass-20-solid" class="text-2xl" />
        </button>

        <UserMenu
          v-if="loggedIn"
          :user="user"
          @signOut="signOut"
          @closeSidebar="closeSidebar"
        />
      </div>
    </div>
  </header>
</template>
