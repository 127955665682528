<script setup>
import { ref, computed, watch } from 'vue'
import {
  TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle,
  Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton,
  Listbox, ListboxButton, ListboxLabel, ListboxOptions, ListboxOption
} from '@headlessui/vue'
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useSearchStore } from '@/stores/SearchStore'
import { icons } from '@/helpers/icons'
import { QUICK_ACTIONS, FAKE_PLAYERS_DATA, FAKE_TEAMS_DATA, FAKE_SETS_DATA, SEARCH_FILTERS } from '@/constants'

import { useBatchStore } from '@/stores/BatchStore'
import debounce from 'lodash/debounce'
import QuickActions from './QuickActions.vue'
import SearchBatchesItem from './SearchBatchesItem.vue'
import SectionHeader from './SectionHeader.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

const route = useRoute()
const router = useRouter()

const globalStore = useGlobalStore()
const searchStore = useSearchStore()
const batchStore = useBatchStore()

const selected = ref('')
const query = ref('')
const inputRef = ref(null)
const selectedCard = ref(null)

const openedSearch = computed(() => searchStore.cdp_search.open)
const triggerOpenSearchResult = computed(() => searchStore.triggerOpenSearchResult)

const searchText = computed(() => {
  if (searchStore.cdp_search.state === 'database') {
    return {
      search_in: 'Card Database',
      placeholder: 'Search card database',
      results_title: 'Database'
    }
  } else if (searchStore.cdp_search.state === 'batches') {
    return {
      search_in: 'Batches',
      placeholder: 'Search all batches',
      results_title: 'Batches'
    }
  }
})

const noResultsFound = computed(() => {
  if (searchStore.selectedFilter === 'Card Database' && query.value && !searchStore.matchesData.matches.length) {
    return true
  } else if ((searchStore.selectedFilter === 'Card Database' && !query.value) || (searchStore.selectedFilter === 'Card Database' && query.value && searchStore.matchesData.matches.length)) {
    return false
  }

  if (searchStore.selectedFilter === 'Batches' && query.value && !searchStore.batchesSearched.length) {
    return true
  } else if ((searchStore.selectedFilter === 'Batches' && !query.value) || (searchStore.selectedFilter === 'Batches' && query.value && searchStore.batchesSearched.length)) {
    return false
  }
  
  if (!searchStore.matchesData.matches?.length && !searchStore.batchesSearched?.length && searchStore.searchState === 'result') {
    return true
  } else {
    return false 
  }
})

const dialogHasOptions = computed(() => {
  if (noResultsFound.value || searchStore.searchState === 'start' || !searchStore.selectedFilter || (searchStore.searchState && query.value) || (searchStore.selectedFilter === 'Card Database') || (searchStore.selectedFilter === 'Batches' && !query.value)) {
    return true
  } else {
    return false
  }
})

const quickActions = computed(() => {
  return QUICK_ACTIONS.filter(
    action => action.text.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
  )
})

const handleLoadMore = () => {
  const searchOptions = { query: query.value, page: searchStore.matchesData.page + 1 }

  if (batchStore.batchKind) {
    searchOptions.kind = batchStore.batchKind
  }  

  searchStore.searchDatabaseMatches(searchOptions)
}

const onSearchCDP = debounce(event => {
  query.value = event.target.value
  if (searchStore.selectedFilter === 'Card Database') {
    if (batchStore.selectedBatch?.id) {
      searchStore.searchDatabaseMatches({ query: query.value, kind: batchStore.selectedBatch.type })
    } else {
      searchStore.searchDatabaseMatches({ query: query.value })
    }
    
    return
  }

  if (searchStore.selectedFilter === 'Batches') {
    if (selectedCard.value) {
      searchStore.searchUserBatches({ query: query.value, kind: selectedCard.value.game ? 'tcg' : selectedCard.value.sport ? 'sports' : '' })
    } else {
      searchStore.searchUserBatches({ query: query.value })
    }
    return
  }

  if (!searchStore.selectedFilter) {
    if (batchStore.selectedBatch?.id) {
      batchStore.batchKind = batchStore.selectedBatch.type
      searchStore.searchDatabaseMatches({ query: query.value, kind: batchStore.selectedBatch.type })
      searchStore.searchUserBatches({ query: query.value, kind: batchStore.selectedBatch.type })
    } else {
      searchStore.searchDatabaseMatches({ query: query.value })
      searchStore.searchUserBatches({ query: query.value })
    }

    searchStore.searchState = 'result'
    return
  }

  if (query.value) {
    searchStore.searchState = 'suggestion'
  } else {
    if (!searchStore.selectedFilter) {
      searchStore.searchState = 'start'
    }
  }
}, 400)

const onRemoveSearch = () => {
  if (!query.value && searchStore.selectedFilter) {
    const searchOptions = {}

    if (batchStore.batchKind) {
      searchOptions.kind = batchStore.batchKind
    }

    searchStore.selectedFilter = ''
    searchStore.searchState = 'result'
    searchStore.searchDatabaseMatches(searchOptions)
    searchStore.searchUserBatches(searchOptions)
  }
}

const selectSearchFilter = option => {
  searchStore.selectedFilter = option

  const batchKind = batchStore.selectedBatch ? batchStore.selectedBatch.type : selectedCard.value?.game ? 'tcg' : selectedCard.value?.sport ? 'sports' : ''

  if (option === 'Card Database') {
    searchStore.searchState = 'result'
    query.value = ''
    inputRef.value.el.focus()
  } else if (option === 'Batches') {
    searchStore.searchState = 'result'
    query.value = ''
    inputRef.value.el.focus()

    if (batchKind) {
      searchStore.searchUserBatches({ kind: batchKind }) 
    } else {
      searchStore.searchUserBatches()
    }
  } else {
    if (batchKind) {
      searchStore.searchUserBatches({ kind: batchKind }) 
    } else {
      searchStore.searchUserBatches() 
    }

    searchStore.searchState = 'suggestion'
  }
}

const fullResults = computed(() => {
  return {
    player: FAKE_PLAYERS_DATA.filter(
      player => player.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    ),
    set: FAKE_SETS_DATA.filter(
      set => set.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    ),
    team: FAKE_TEAMS_DATA.filter(
      team => team.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    ),
    // inventory: FAKE_PLAYERS_DATA.filter(
    //   player => player.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    // ),
    inventory: [],
    // batches: FAKE_SETS_DATA.filter(
    //   set => set.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    // ),
    batches: [],
    database: FAKE_TEAMS_DATA.filter(
      team => team.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    ),
    // quickActions: QUICK_ACTIONS.filter(
    //   action => action.text.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''))
    // ),
  }
})

const visibleSuggestions = computed(() => {
  let playerLength = fullResults.value.player.length
  let setLength = fullResults.value.set.length
  let teamLength = fullResults.value.team.length
  let fullLength = setLength + playerLength + teamLength
  let maxLength = 9

  let suggestionObj = {
    player: [],
    set: [],
    team: [],
  }

  if (fullLength <= maxLength) {
    suggestionObj.player = fullResults.value.player
    suggestionObj.set = fullResults.value.set
    suggestionObj.team = fullResults.value.team
  } else {
    // if (playerLength <= 3) {
    //   suggestionObj.player = fullResults.value.player
    // } else {
    //   suggestionObj.player = fullResults.value.player.slice(0, 3)
    // }
    // if (setLength <= 3) {
    //   suggestionObj.set = fullResults.value.set
    // } else {
    //   suggestionObj.set = fullResults.value.set.slice(0, 3)
    // }
    // if (teamLength <= 3) {
    //   suggestionObj.team = fullResults.value.team
    // } else {
    //   suggestionObj.team = fullResults.value.team.slice(0, 3)
    // }

    if (playerLength <= 3) {
      suggestionObj.player = fullResults.value.player
    } else {
      if (setLength < 3 || teamLength < 3) {
        if (setLength === 0) {
          if (teamLength === 0) {
            if (playerLength >= maxLength) {
              suggestionObj.player = fullResults.value.player.slice(0, maxLength)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          } else if (teamLength <= 3) {
            if (playerLength >= maxLength - teamLength) {
              suggestionObj.player = fullResults.value.player.slice(0, maxLength - teamLength)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          } else {
            if (playerLength >= 4) {
              suggestionObj.player = fullResults.value.player.slice(0, 4)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          }
        } else if (setLength <= 3) {
          if (teamLength === 0) {
            if (playerLength >= maxLength - setLength) {
              suggestionObj.player = fullResults.value.player.slice(0, maxLength - setLength)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          } else if (teamLength <= 3) {
            if (playerLength >= maxLength - (teamLength + setLength)) {
              suggestionObj.player = fullResults.value.player.slice(0, maxLength - (teamLength + setLength))
            } else {
              suggestionObj.player = fullResults.value.player
            }
          } else {
            if (playerLength >= 4) {
              suggestionObj.player = fullResults.value.player.slice(0, 4)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          }
        } else {
          if (teamLength === 0) {
            if (playerLength >= 4) {
              suggestionObj.player = fullResults.value.player.slice(0, 4)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          } else if (teamLength < 3) {
            if (playerLength >= 4) {
              suggestionObj.player = fullResults.value.player.slice(0, 4)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          } else {
            if (playerLength >= 3) {
              suggestionObj.player = fullResults.value.player.slice(0, 3)
            } else {
              suggestionObj.player = fullResults.value.player
            }
          }
        }
      } else {
        suggestionObj.player = fullResults.value.player.slice(0, 3)
      }
    }

    if (setLength <= 3) {
      suggestionObj.set = fullResults.value.set
    } else {
      if (playerLength < 3 || teamLength < 3) {
        if (playerLength === 0) {
          if (teamLength === 0) {
            if (setLength >= maxLength) {
              suggestionObj.set = fullResults.value.set.slice(0, maxLength)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          } else if (teamLength <= 3) {
            if (setLength >= maxLength - teamLength) {
              suggestionObj.set = fullResults.value.set.slice(0, maxLength - teamLength)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          } else {
            if (setLength >= 4) {
              suggestionObj.set = fullResults.value.set.slice(0, 4)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          }
        } else if (playerLength <= 3) {
          if (teamLength === 0) {
            if (setLength >= maxLength - playerLength) {
              suggestionObj.set = fullResults.value.set.slice(0, maxLength - playerLength)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          } else if (teamLength <= 3) {
            if (setLength >= maxLength - (teamLength + playerLength)) {
              suggestionObj.set = fullResults.value.set.slice(0, maxLength - (teamLength + playerLength))
            } else {
              suggestionObj.set = fullResults.value.set
            }
          } else {
            if (setLength >= 4) {
              suggestionObj.set = fullResults.value.set.slice(0, 4)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          }
        } else {
          if (teamLength === 0) {
            if (setLength >= 4) {
              suggestionObj.set = fullResults.value.set.slice(0, 4)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          } else if (teamLength < 3) {
            if (setLength >= 4) {
              suggestionObj.set = fullResults.value.set.slice(0, 4)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          } else {
            if (setLength >= 3) {
              suggestionObj.set = fullResults.value.set.slice(0, 3)
            } else {
              suggestionObj.set = fullResults.value.set
            }
          }
        }
      } else {
        suggestionObj.set = fullResults.value.set.slice(0, 3)
      }
    }
    
    if (teamLength <= 3) {
      suggestionObj.team = fullResults.value.team
    } else {
      if (playerLength < 3 || setLength < 3) {
        if (playerLength === 0) {
          if (setLength === 0) {
            if (teamLength >= maxLength) {
              suggestionObj.team = fullResults.value.team.slice(0, maxLength)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          } else if (setLength <= 3) {
            if (teamLength >= maxLength - setLength) {
              suggestionObj.team = fullResults.value.team.slice(0, maxLength - setLength)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          } else {
            if (teamLength >= 4) {
              suggestionObj.team = fullResults.value.team.slice(0, 4)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          }
        } else if (playerLength <= 3) {
          if (setLength === 0) {
            if (teamLength >= maxLength - playerLength) {
              suggestionObj.team = fullResults.value.team.slice(0, maxLength - playerLength)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          } else if (setLength <= 3) {
            if (teamLength >= maxLength - (setLength + playerLength)) {
              suggestionObj.team = fullResults.value.team.slice(0, maxLength - (setLength + playerLength))
            } else {
              suggestionObj.team = fullResults.value.team
            }
          } else {
            if (teamLength >= 4) {
              suggestionObj.team = fullResults.value.team.slice(0, 4)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          }
        } else {
          if (teamLength === 0) {
            if (teamLength >= 4) {
              suggestionObj.team = fullResults.value.team.slice(0, 4)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          } else if (teamLength < 3) {
            if (teamLength >= 4) {
              suggestionObj.team = fullResults.value.team.slice(0, 4)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          } else {
            if (teamLength >= 3) {
              suggestionObj.team = fullResults.value.team.slice(0, 3)
            } else {
              suggestionObj.team = fullResults.value.team
            }
          }
        }
      } else {
        suggestionObj.team = fullResults.value.team.slice(0, 3)
      }
    }
  }

  return suggestionObj
})

const showResults = async (value, type = '') => {
  if (searchStore.selectedFilter && !batchStore.selectedBatch && type === 'card') {
    selected.value = value
    searchStore.selectedFilter = 'Batches'
    inputRef.value.el.value = ''
    searchStore.cdp_search.locationOfSearchCall = 'add-button'
    selectSearchFilter('Batches')
    return
  }

  if (!searchStore.selectedFilter && batchStore.selectedBatch && !type) {
    batchStore.selectedBatch = { ...value }
    batchStore.selectedBatchId = value.id 
    batchStore.fetchSelectedBatchCards()

    closeDialog()
    return
  }

  if (searchStore.cdp_search.locationOfSearchCall === 'add-match') {
    batchStore.updateMatchedCard(value)
    closeDialog()
    return
  }

  if (searchStore.cdp_search.locationOfSearchCall === 'add-card-button' || (batchStore.selectedBatch && type === 'card')) {
    batchStore.importMatchedCard(value)
    closeDialog()
    return
  }

  if (searchStore.cdp_search.locationOfSearchCall === 'add-button' && searchStore.selectedFilter === 'Batches') {
    if (selected.value) {
      batchStore.selectedBatchId = value.id
      batchStore.importMatchedCard(selected.value)
      selected.value = null
    } else {
      router.push(`/batches/${value.id}/add/types`)
    }
    
    closeDialog()
    return
  } else if (searchStore.selectedFilter === 'Batches' && selectedCard.value) {
    batchStore.selectedBatchId = value.id
    await batchStore.importMatchedCard(selectedCard.value)
    router.push(`/batches/${value.id}`)
    selectedCard.value = null
    closeDialog()
    return
  } else if (searchStore.selectedFilter === 'Batches') {
    router.push(`/batches/${value.id}`)
    batchStore.updateSelectedBatch(value)
    batchStore.fetchSelectedBatchCards()
    closeDialog()
    return
  }
  
  if (!window.getSelection().toString()) {
    query.value = ''
    inputRef.value.el.value = ''
    inputRef.value.el.focus()
    selectedCard.value = value

    selectSearchFilter('Batches')
    localStorage.setItem(`selectedCard`, JSON.stringify(value))
    
    searchStore.cdp_search = { ...searchStore.cdp_search, locationOfSearchCall: '' }
  }
}

const openDialog = () => {
  searchStore.cdp_search.open = true

  if (!searchStore.selectedFilter) {
    searchStore.searchState = 'start'
  }
}

const closeDialog = () => {
  batchStore.batchKind = ''
  searchStore.cdp_search.open = false
  searchStore.triggerOpenSearchResult = false
  setTimeout(() => {
    searchStore.selectedFilter = ''
    query.value = ''
    selected.value = ''
    selectedCard.value = null
    searchStore.searchState = 'start'
  }, 300);
}

watch(openedSearch, () => {
  setTimeout(() => {
    openedSearch && inputRef.value && inputRef.value.el.focus()
  }, 0)

  if (!openedSearch.value) {
    searchStore.matchesData = { matches: [] }
  }
})

watch(triggerOpenSearchResult, () => {
  triggerOpenSearchResult.value && openDialog()
})
</script>


<template>
  <div class="block relative z-10 w-[27rem] text-sm">
    <div
      class="
        relative overflow-hidden rounded-full items-center justify-between gap-x-3 bg-white border py-1.5
        px-2.5 h-9 text-gray-600 cursor-pointer hidden md:flex
      "
      @click="openDialog"
    >
      <Icon icon="heroicons:magnifying-glass" class="text-lg" />
      <div class="pointer-events-none flex items-center gap-x-2">
        <Icon
          icon="mdi:apple-keyboard-command"
          class="w-6 h-6 rounded p-1 bg-gray-100"
          v-if="globalStore.userAgent === 'cmd'"
        />
        <Icon icon="vaadin:ctrl-a" class="w-6 h-6 rounded p-1 bg-gray-100" v-else />
        <span>+</span>
        <span class="bg-gray-100 w-6 h-6 rounded flex items-center justify-center"> K </span>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="searchStore.cdp_search.open">
    <Dialog as="div" class="relative z-[9999] text-xs" @close="closeDialog">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-[0.38] transition-opacity backdrop-blur-sm" />
      </TransitionChild>
  
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full w-full">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enter-to="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 md:scale-100"
            leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <DialogPanel class="relative mx-auto py-2 w-[50rem] h-fit">
              <Combobox
                :modelValue="selected"
                @update:modelValue="showResults"
              >
                <header
                  class="flex items-center gap-x-2 py-2.5 px-4 bg-white relative z-10"
                  :class="dialogHasOptions ? 'rounded-t-xl' : 'rounded-3xl'"
                >
                  <Icon icon="heroicons:magnifying-glass" class="text-lg flex-none" />
                  <Listbox v-model="searchStore.selectedFilter" class="relative flex-none" as="div" v-if="searchStore.selectedFilter">
                    <ListboxButton class="rounded-sm bg-blue-100 py-0.5 px-1">in: {{ searchStore.selectedFilter }}</ListboxButton>
                    <ListboxOptions
                      class="absolute top-full mt-px left-0 w-28 child:p-1.5 child:cursor-pointer"
                    >
                      <ListboxOption
                        v-for="(option, index) in SEARCH_FILTERS"
                        :value="option"
                        :key="index"
                        v-slot="{ selected }"
                        as="template"
                      >
                        <li v-text="option" :class="selected ? 'bg-blue-200 font-semibold text-tint-1' : 'bg-blue-100'" />
                      </ListboxOption>
                    </ListboxOptions>
                  </Listbox>
                  <ComboboxInput
                    class="bg-transparent focus:ring-0 border-0 p-0 text-sm flex-1"
                    @change.stop.prevent="onSearchCDP"
                    @keydown.delete="onRemoveSearch"
                    :placeholder="searchText && searchText?.value ? searchText.value.placeholder : 'Search card database and batches ...'"
                    ref="inputRef"
                  />
                  <button @click="closeDialog" class="block md:hidden z-20">
                    <Icon icon="heroicons:x-mark" class="text-base" />
                  </button>
                </header>

                <ComboboxOptions
                  class="
                    absolute top-12 left-0 border-t max-h-[calc(100vh-6rem)] w-full overflow-y-auto rounded-b-xl
                    bg-white focus:outline-none
                  "
                  v-if="dialogHasOptions"
                  static
                >
                  <div v-if="searchStore.loadingMatches || searchStore.loadingBatches" class="h-[294px] flex justify-center">
                    <LoadingSpinner :width="'112px'" :height="'112px'" />
                  </div>
                 

                  <!-- No Results found box -->
                  <template v-else>
                    <section v-if="noResultsFound" class="flex flex-col justify-center items-center gap-y-4 py-16">
                      <span v-html="icons.error_document" class="child:w-16 child:h-16" />
                      <p class="text-xs text-gray-500 font-medium">No results found</p>
                    </section>
  
                    <template v-else-if="searchStore.searchState === 'start'">
                      <template v-if="!searchStore.selectedFilter">
                        <!-- Search Filters Box -->
                        <section class="py-3 px-4 border-b">
                          <h3 class="font-semibold text-gray-400 mb-2">Add filters:</h3>
                          <ul class="flex items-center gap-x-3">
                            <li
                              class="rounded-sm bg-blue-100 py-0.5 px-1 cursor-pointer"
                              v-for="(option, index) in SEARCH_FILTERS"
                              :key="index"
                              v-text="option"
                              @click="selectSearchFilter(option)"
                            />
                          </ul>
                        </section>
                      </template>
                      <!-- <section class="py-3 border-b">
                        // Recent Searches box
                        <section class="pb-3" v-if="searchStore.recentSearches.length">
                          <h3 class="font-semibold text-gray-400 mb-2 px-4">Recent searches</h3>
                          <ul>
                            <li
                              class="flex items-center gap-x-2 px-4 py-2 transition hover:bg-blue-50 group cursor-pointer"
                              v-for="option, idx in searchStore.recentSearches.slice(0, 6)"
                              :key="idx"
                              @click="showResults(option)"
                            >
                              <Icon icon="heroicons:clock" class="text-base text-gray-500" />
                              <span v-text="option" class="flex-1" />
                              <button
                                class="opacity-0 invisible transition group-hover:visible group-hover:opacity-100"
                                @click.prevent="searchStore.recentSearches.splice(idx, 1)"
                              >
                                <Icon icon="heroicons:x-mark" class="text-base text-gray-500" />
                              </button>
                            </li>
                          </ul>
                        </section>
  
                        // Quick actions box
                        <section v-if="QUICK_ACTIONS.length">
                          <QuickActions @closeDialog="closeDialog" :options="QUICK_ACTIONS.slice(0, 6)" />
                        </section>
                      </section> -->
                    </template>
  
                    <!-- <template v-else-if="searchStore.searchState === 'suggestion' && query">
                      <ComboboxOption :value="query" as="template" v-slot="{ active }">
                        <li
                          class="py-2 px-4 flex items-center justify-between transition cursor-pointer"
                          :class="{ 'bg-blue-50': active }"
                          >
                            <div class="flex items-center gap-x-2 flex-1">
                              <Icon icon="heroicons:magnifying-glass" class="text-base text-gray-500" />
                              <span class="flex-1" :class="{ 'font-semibold': active }" v-text="query"></span>
                            </div>
                            <div class="flex items-center gap-x-1">
                              <Icon icon="heroicons:arrow-uturn-left-solid" class="w-6 h-6 p-1 bg-gray-100 rounded text-gray-500 -scale-y-100" />
                              <Icon icon="heroicons:chevron-right" class="text-base text-tint-1" />
                            </div>
                        </li>
                      </ComboboxOption>
                      <template v-for="(suggestions, name) in visibleSuggestions">
                        <ComboboxOption 
                          v-for="option, index in suggestions" 
                          :value="option" 
                          :key="index"
                          as="template" 
                          v-slot="{ active }"
                        >
                          <li
                            class="py-2 px-4 flex items-center justify-between transition cursor-pointer"
                            :class="{ 'bg-blue-50': active }"
                          >
                            <div class="flex items-center gap-x-2 flex-1">
                              <Icon icon="heroicons:magnifying-glass" class="text-base text-gray-500" />
                              <span class="flex-1" :class="{ 'font-semibold': active }" v-text="option"></span>
                            </div>
                            <div class="flex items-center gap-x-1">
                              <span v-text="name" class="capitalize text-gray-500"></span>
                              <Icon icon="heroicons:chevron-right" class="text-base text-tint-1" />
                            </div>
                          </li>
                        </ComboboxOption>
                      </template>
                    </template> -->
  
                    <template v-else-if="searchStore.searchState === 'result'">
                      <!-- <template v-if="fullResults.inventory.length">
                        <section v-if="!searchStore.selectedFilter || searchStore.selectedFilter === 'Inventory'">
                          <header class="flex items-center justify-between bg-gray-100 py-2 px-4">
                            <h3 class="font-semibold text-gray-500">Inventory</h3>
                            <button
                              class="inline-flex items-center gap-x-1 font-medium text-tint-1 transition hover:text-tint-2"
                              @click="searchStore.selectedFilter = 'Inventory'"
                              v-if="searchStore.selectedFilter !== 'Inventory'"
                            >
                              All inventory results <Icon icon="heroicons:chevron-right" class="text-base" />
                            </button>
                          </header>
                          <ul>
                            <li
                              class="flex items-center gap-x-2 p-3 transition duration-300 hover:bg-blue-50 cursor-pointer group"
                              v-for="option, index in fullResults.inventory.length"
                              :key="index"
                            >
                              <div class="w-10 h-10 relative flex-none flex items-center justify-center">
                                <img src="@/assets/images/place-cards/3.png" class="relative z-10 max-h-10" />
                                <img
                                  src="@/assets/images/place-cards/5.png"
                                  class="absolute top-1/2 -translate-y-1/2"
                                />
                              </div>
                              <div class="flex flex-col flex-grow">
                                <h3 class="font-medium mb-0.5">[Card #] [Player/Card Desc]</h3>
                                <p class="text-gray-500 mb-1.5">[Year] [Brand] [Set]</p>
                                <div class="flex items-center flex-wrap gap-1 text-[10px] mt-auto">
                                  <span class="badge secondary border border-white px-1.5 rounded-full">Parallel</span>
                                  <span class="badge secondary border border-white px-1.5 rounded-full">Variation</span>
                                  <span class="badge secondary border border-white px-1.5 rounded-full">Attribute</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </section>
                      </template> -->

                      <template v-if="!searchStore.selectedFilter">
                        <!-- Search Filters Box -->
                        <section class="py-3 px-4 border-b">
                          <h3 class="font-semibold text-gray-400 mb-2">Add filters:</h3>
                          <ul class="flex items-center gap-x-3">
                            <li
                              class="rounded-sm bg-blue-100 py-0.5 px-1 cursor-pointer"
                              v-for="(option, index) in SEARCH_FILTERS"
                              :key="index"
                              v-text="option"
                              @click="selectSearchFilter(option)"
                            />
                          </ul>
                        </section>
                      </template>

                      <template v-if="searchStore.selectedFilter === 'Batches' && batchStore.selectedBatch && searchStore.cdp_search.locationOfSearchCall === 'add-button'">
                        <section>
                          <SectionHeader :placeholder="'Add to this batch'" />
                          
                          <ComboboxOption
                            :value="batchStore.selectedBatch"
                            as="div"
                            v-slot="{ active }"
                          >
                            <SearchBatchesItem :active="active" :item="batchStore.selectedBatch" />
                          </ComboboxOption> 
                        </section>
                      </template>

                      <template v-if="searchStore.selectedFilter === 'Batches' && searchStore.recentlyModifiedBatches.length">
                        <section>
                          <SectionHeader :placeholder="'Recently modified'" />
                         
                          <ComboboxOption
                            v-for="(option, index) in searchStore.recentlyModifiedBatches"
                            :key="index"
                            :value="option"
                            v-slot="{ active }"
                          >
                            <SearchBatchesItem :active="active" :item="option" :selectedCard="selectedCard" />
                            
                          </ComboboxOption>  
                        </section>
                      </template>
  
                      <template v-if="searchStore.batchesSearched.length">
                        <section v-if="!searchStore.selectedFilter || searchStore.selectedFilter === 'Batches'">
                          <SectionHeader :placeholder="'Batches'" :items="searchStore.batchesSearched" :filter="'Batches'" :buttonName="'All batch results'" />
  
                          <ComboboxOption
                            v-for="(option, index) in !searchStore.selectedFilter ? searchStore.batchesSearched.slice(0, 3) : searchStore.batchesSearched"
                            :key="index"
                            :value="option"
                            v-slot="{ active }"
                          >
                            <SearchBatchesItem :active="active" :item="option" :selectedCard="selectedCard" />
                          </ComboboxOption>  
                        </section>
                      </template>
  
                      <template v-if="searchStore.matchesData.matches.length">
                        <section v-if="!searchStore.selectedFilter || searchStore.selectedFilter === 'Card Database'">
                          <SectionHeader :placeholder="'Database'" :items="searchStore.matchesData.matches" :filter="'Card Database'" :buttonName="'All database results'" />
                          
                          <ComboboxOption
                            v-for="(option, index) in !searchStore.selectedFilter ? searchStore.matchesData.matches.slice(0, 3) : searchStore.matchesData.matches"
                            :key="index"
                            :value="option"
                            v-slot="{ active }"
                          >
                            <li                               
                              class="flex items-center gap-x-2 p-3 transition duration-300 hover:bg-blue-50 cursor-pointer group"
                              :class="{ 'bg-blue-50': active }"
                              @click.stop.prevent="() => {}"
                            >
                              <div class="w-10 h-10 relative flex-none flex items-center justify-center">
                                <img :src="option.frontImageUrl" class="relative z-10 max-h-10" />
                                <img
                                  :src="option.backImageUrl"
                                  class="absolute top-1/2 -translate-y-1/2"
                                  v-if="option.backImageUrl"
                                />
                              </div>
                              <div class="flex flex-col flex-grow">
                                <h3 class="font-medium mb-0.5">#{{ option.number }} {{ option.name ? option.name : option.player ? option.player.name : '' }}</h3>
                                <p class="text-gray-500 mb-1.5">{{ option.set?.name }}</p>
                                <div class="flex items-center flex-wrap gap-1 text-[10px] mt-auto">
                                  <span v-if="option.subset" class="badge secondary border border-white px-1.5 rounded-full">{{ option.subset.name }}</span>
                                  <span v-if="option.variation" class="badge secondary border border-white px-1.5 rounded-full">{{ option.variation.name }}</span>
                                  <span v-if="option.attributes" class="badge secondary border border-white px-1.5 rounded-full">{{ option.attributes.name }}</span>
                                </div>
                              </div>
                              <button
                                class="
                                  alternative-button text-xs py-1 text-tint-1 focus:ring-tint-1/20 transition duration-300 opacity-0
                                  group-hover:opacity-100
                                "
                                :class="{'!opacity-100': active}"
                                @click.stop.prevent="() => showResults(option, 'card')"
                              >
                                <span v-if="searchStore.cdp_search.locationOfSearchCall === 'add-match'">Select Match</span>
                                <span v-else>Add Card</span>
                              </button>
                            </li>
                            
                          </ComboboxOption>
                          <ComboboxOption v-if="(searchStore.matchesData.totalHits > searchStore.matchesData.matches.length) && searchStore.selectedFilter === 'Card Database'">
                            <div class="flex flex-col justify-center items-center gap-y-3 py-4">
                              <button class="alternative-button text-tint-1 text-sm" @click.stop.prevent="handleLoadMore">Load more</button>
                            </div>
                          </ComboboxOption>
                        </section>
                      </template>
  
                      <!-- <section v-if="!searchStore.selectedFilter && quickActions.length">
                        <QuickActions @closeDialog="closeDialog" :options="quickActions"/>
                      </section> -->
                    </template>
                  </template> 
                </ComboboxOptions>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>